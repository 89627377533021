import React from "react";
import "./loading-styles.css";

const Loading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center fixed z-50 bg-gray-900/30 top-0 left-0">
      <div className="loading-wrapper ">
        <div className="lds-dual-ring" />
      </div>
    </div>
  );
};

export default Loading;
